import React from "react";
import { Box } from "@mui/material";

import { HeaderBlockProps } from "interfaces/header.interface";
import { LanguageSiteList } from "components/static/language/LanguageSiteList";


const LanguageBlock: React.FC<HeaderBlockProps> = ({ colorSvg }) => {
  return (
    <Box sx={{ px: 0 }}>
      <LanguageSiteList/>
    </Box>
  );
};

export { LanguageBlock };
