import React from "react";
import { TopNavigatorItem } from "./TopNavigatorItem";
import { NavigatorDto } from "dto/static/navigator.dto";
import { Box } from "@mui/material";
import { useDrawer } from "hooks/useDrawer";
import { TopNavigatorList } from "./TopNavigatorList";
import { useResponsive } from "hooks/useResponsive";

const HeaderNavigator: React.FC = () => {
  const { menuObjects } = useDrawer();
  const {downMD} = useResponsive();
  const processItem = (item: NavigatorDto, index: number) => {
    if (!item) return null;
    return (
      <Box key={index}>
        <TopNavigatorItem item={item} level={1} color={"white"} />
      </Box>
    );
  };

  if(downMD) return null;
  if (!menuObjects) return null;
  return <TopNavigatorList objects={menuObjects} processItem={processItem} />;
};

export { HeaderNavigator };
