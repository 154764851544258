import { Box } from "@mui/material";
import { Image } from "components/elements/display/Image";

import { PageDto } from "dto/static/page.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: PageDto;
};

const PageMainImage: React.FC<Props> = ({ object }) => {
  if (!CommonTools.processObjectField(object, ["_galleryDefault", "cdnurl"]))
    return null;
  return (
    <Box sx={{ maxWidth: "100%"}} my={4}>
      <Image
        url={CommonTools.processObjectField(object, [
          "_galleryDefault",
          "cdnurl",
        ])}
        typeMeasure="percentage"
        withLinK={false}
     
        sizeInPercentage={{
          width: "100%",
          height: "auto",
        }}
      />
    </Box>
  );
};

export { PageMainImage };
