import { Box, Grid, Typography } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyTextField } from "components/elements/form/MyTextField";
import { SelectTypeDepartment } from "components/nomenclature/SelectTypeDepartament";

import { ContactRequestDTO } from "dto/static/contactrequest.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React, { useEffect } from "react";

import RequiredValidator from "validators/required.validator";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

type Props = {
  defaultObject: ContactRequestDTO;
  onSubmit: (data: ContactRequestDTO) => void;
  loading: boolean;
};
const FormContactRequest: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , setObject] = useForm<ContactRequestDTO>(
    defaultObject,
    RequiredValidator.getValidators([
      "name",
      "email",
      "phone",
      "message",
      "idtypedepartment",
    ])
  );

  useEffect(() => {
    if (!defaultObject) return;
    setObject(defaultObject);
  }, [defaultObject]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    onSubmit(obj);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container rowSpacing={0} columnSpacing={1}>
          <Grid item sm={6} xs={12} sx={{ marginBottom: "6px" }}>
            <SelectTypeDepartment
              setObjectField={setObjField}
              value={obj.idtypedepartment}
            />
          </Grid>
          <Grid item sm={6} xs={12} sx={{ marginBottom: "6px" }}>
            <MyTextField
              field="name"
              label={LL("name")}
              setObjectField={setObjField}
              value={obj.name}
              className="input"
            />
          </Grid>
          <Grid item sm={6} xs={12} sx={{ marginBottom: "6px" }}>
            <MyTextField
              field="email"
              label={LL("email")}
              setObjectField={setObjField}
              value={obj.email}
              className="input"
            />
          </Grid>
          <Grid item sm={6} xs={12} sx={{ marginBottom: "6px" }}>
            <MyTextField
              field="phone"
              label={LL("phone")}
              setObjectField={setObjField}
              value={obj.phone}
              className="input"
            />
          </Grid>

          <Grid item xs={12} sx={{ marginBottom: "6px" }}>
            <MyTextField
              field="message"
              label={LL("message")}
              setObjectField={setObjField}
              value={obj.message}
              multiline
              minRows={30}
              className=" input textarea"
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonWithLoading
              type="submit"
              // disabled={loading || disabled}
              disabled={false}
              loading={loading}
              className="btnSubmit emailButtonMain button112"
              sx={{ mt: 3 }}
              fullWidth>
              <Typography className="text">{LL("btn_submit")}</Typography>
              <Box sx={{}} className="icon-container">
                <Box className="icon">
                  <MailOutlineIcon />
                </Box>
              </Box>
            </ButtonWithLoading>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export { FormContactRequest };
