import { Loading } from "components/elements/loading/Loading";
import { useReactPath } from "hooks/useReactPath";
import { useResource } from "hooks/useResource";

import { ForbiddenPage } from "pages/general/ForbiddenPage";
import { HomePage } from "pages/general/HomePage";
import { NotFoundPage } from "pages/general/NotFoundPage";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { RouteTools } from "tools/utils/route.tool";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { StaticPage } from "pages/static/StaticPage";
import { ServiceStatic } from "pages/static/ServiceStatic";
import { MyDrawer } from "components/drawer/MyDrawer";
import { useDrawer } from "hooks/useDrawer";

const MainLayout: React.FC = () => {
  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();
  const [_currentpath, setCurrentPath] = useState("");
  const [_mainobject, setMainObject] = useState("");
  const { getListNavigator } = useDrawer();
  const stylePages = { marginTop: "0px" };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const processPathObject = async () => {
    let routeobj = await RouteTools.parseLocation(location);

    routeobj.historystate = pathObject.historystate;

    if (routeobj.url == _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  };

  useEffect(() => {
    processPathObject();
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  useEffect(() => {
    getListNavigator();
  }, [getListNavigator]);

  const processStandard = () => {
    if (!_mainobject) return false;
    if (_mainobject === "homepage")
      return <HomePage currentRoute={currentRoute} />;

    if (_mainobject === "forbidden")
      return <ForbiddenPage currentRoute={currentRoute} />;

    if (_mainobject === "notfound")
      return <NotFoundPage currentRoute={currentRoute} />;

    return false;
  };

  const processPage = () => {
    if (!_mainobject) return false;

    if (_mainobject === "page")
      return (
        <div style={stylePages}>
          <StaticPage currentRoute={currentRoute} />
        </div>
      );

    return false;
  };

  const processService = () => {
    if (!_mainobject) return false;

    if (_mainobject === "service")
      return (
        <div style={stylePages}>
          <ServiceStatic currentRoute={currentRoute} />
        </div>
      );

    return false;
  };

  const processMainObject = () => {
    let t: any = false;

    t = processStandard();
    if (t) return t;

    t = processPage();
    if (t) return t;

    t = processService();
    if (t) return t;

    return <Loading />;
  };

  const processMainLayout = () => {
    return (
      <>
        <header>
          <Header currentRoute={currentRoute} />
        </header>
        <main>{processMainObject()}</main>
        <footer>
          <Footer currentRoute={currentRoute} />
        </footer>
        <MyDrawer />
      </>
    );
  };

  return processMainLayout();
};

export { MainLayout };
