import { Box, Slide, Typography } from "@mui/material";
import { GeneralDialog } from "components/elements/display/GeneralDialog";
import React from "react";
import { PhoneAction } from "../phone/PhoneAction";

import { useLabel } from "hooks/useLabel";
import { FormContactRequest } from "./FormContactRequest";
import { useRequestContact } from "hooks/useRequestContact";
import { TransitionProps } from "@mui/material/transitions";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const ContactRequestDialog: React.FC<Props> = ({ open, handleClose }) => {
  const callback = () => {
    handleClose();
  };
  const { defaultObject, onSubmit, loading } = useRequestContact(callback);
  const { LL } = useLabel();

  return (
    <React.Fragment>
      <GeneralDialog
        TransitionComponent={Transition}
        open={open}
        handleClose={handleClose}>
        {/* <Typography variant="h4">{LL("contact_us_by_title")}</Typography> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            mt: 3,
            py: 2,
            px: 1,
          }}>
          <Typography variant="h3" sx={{ mb: 4 }}>
            {LL("contact_us_by_phone")}
          </Typography>
          <PhoneAction className={"headerCallNow"} label={true} />
          <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
            {LL("or_send_email")}
          </Typography>
          <Box className="contactPage">
            <FormContactRequest
              defaultObject={defaultObject}
              onSubmit={onSubmit}
              loading={loading}
            />
          </Box>
        </Box>
      </GeneralDialog>
    </React.Fragment>
  );
};

export { ContactRequestDialog };
