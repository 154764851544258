import { Box } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";
import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

const CopyRightPage: React.FC = () => {
  const id = Config.ID_COPYRIGHT_PAGE;
  const { object, loading } = usePage(id, true, "copy_right_page");

  if(!id) return null;
  if (loading) return null;
  if (!object) return null;
  return (
    <>
      <HtmlTag content={CommonTools.processObjectField(object, ["_description"])} />
    </>
  );
};

export { CopyRightPage };
