import React from "react";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { Breadcrumbs } from "@mui/material";
import { BreadcrumbItem } from "./BreadcrumbItem";
import { useLabel } from "hooks/useLabel";

type Props = {
  objects: BreadcrumbDto[];

  processItem?: (item: BreadcrumbDto, index: number) => JSX.Element;
};

const MyBreadcrumbs: React.FC<Props> = ({ objects, processItem }) => {
  const { LL } = useLabel();
  const arr = BreadcrumbDto.prepareBreadcrumbForDisplay(objects, LL);

  return (
    <Breadcrumbs
      separator="/"
      className="breadcrumbs"
      aria-label="breadcrumb"
      color="text.disabled"
    >
      {arr.map((item, index) =>
        processItem ? (
          processItem(item, index)
        ) : (
          <BreadcrumbItem key={index} item={item} index={index} />
        )
      )}
    </Breadcrumbs>
  );
};

export { MyBreadcrumbs };
