import { ContactRequestDTO } from "dto/static/contactrequest.dto";
import { useLabel } from "./useLabel";
import { useMessage } from "providers/MessageProvider";
import { useState } from "react";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { MessageTypes } from "tools/types/messagetypes";
import { ContactRequestService } from "services/static/contactrequest.service";

type UseRequestContact = (
    callback?: () => void
) => {
  onSubmit: (obj: ContactRequestDTO) => void;
  loading: boolean;
  defaultObject: ContactRequestDTO;
};

const service = new ContactRequestService();
export const useRequestContact: UseRequestContact = (callback) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultObject, setDefaultObject] = useState<ContactRequestDTO>(
    new ContactRequestDTO()
  );
  const { registerMessage } = useMessage();
  const { LL } = useLabel();
  const onSubmit = (obj: ContactRequestDTO) => {
    if (!obj) return;
    setLoading(true);
    service.add(handleResult, {}, obj);
  };
  const handleResult = (result: ResultObjectDTO<ContactRequestDTO>) => {
    if (!result) {
      setLoading(false);
      return;
    }
    setLoading(false);
    setDefaultObject(new ContactRequestDTO());
    if(callback) callback();
    registerMessage(
      LL("you_message_was_sent"),
      MessageTypes.MESSAGE_SUCCESS,
      "200"
    );
  };

  return { onSubmit, loading, defaultObject };
};
