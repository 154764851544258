import { Box, Typography } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";

import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

import { SkeletonAboutUs } from "components/elements/skeleton/SkeletonAboutUs";

const ContactHome: React.FC = () => {
  const id = Config.ID_CONTACTS_PAGE;
  const { object, loading } = usePage(id, true, "contacts_block_home");

  if (!id) return null;
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonAboutUs />
      </Box>
    );
  if (!object) return null;
  return (
    <>
      <Typography variant="h2" mb={5}>
        {CommonTools.processObjectField(object, ["_name"])}
      </Typography>
      <Typography className="text" component={"div"}>
        <HtmlTag
          content={CommonTools.processObjectField(object, ["_description"])}
        />
      </Typography>
    </>
  );
};

export { ContactHome };
