import { Grid } from "@mui/material";

import { FormContactRequest } from "./FormContactRequest";

import { ContactHome } from "../page/block/ContactHome";
import { useRequestContact } from "hooks/useRequestContact";

const ContactRequestHome: React.FC = () => {
  const { defaultObject, onSubmit, loading } = useRequestContact();
  return (
    <Grid container className="paddingGeneral container contactPage">
      <Grid item xs={12} md={6}>
        <ContactHome />
      </Grid>
      <Grid item xs={12} md={1}></Grid>
      <Grid item xs={12} md={5}>
        <FormContactRequest
          defaultObject={defaultObject}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export { ContactRequestHome };
