import { Grid, Typography } from "@mui/material";
import { BenefitDTO } from "dto/static/benefit.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  item: BenefitDTO;
};

const BenefitItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Grid item xs={6} className="numbers" mb={3}>
      <Typography variant="h3">{CommonTools.processObjectField(item, ["_info"])}</Typography>
      <Typography className="description">{CommonTools.processObjectField(item, ["_name"])}</Typography>
    </Grid>
  );
};

export { BenefitItem };
