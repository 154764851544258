import { Box, Typography } from "@mui/material";
import { AttachmentList } from "components/elements/attachment/AttachmentList";
import { GalleryList } from "components/elements/gallery/GalleryList";
import { ServiceBreadcrumb } from "components/static/service/page/ServiceBreadcrumb";
import { ServiceContent } from "components/static/service/page/ServiceContent";
import { ServiceMainImage } from "components/static/service/page/ServiceMainImage";

import { ServiceTitle } from "components/static/service/page/ServiceTitle";
import { ServiceVideo } from "components/static/service/page/ServiceVideo";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { ServiceDto } from "dto/static/service.dto";

import { IPage } from "interfaces/page.interface";
import React, { useCallback, useEffect, useState } from "react";
import { ServiceService } from "services/static/service.service";
import { CommonTools } from "tools/utils/common.tool";
import { SkeletonBreadCrumb } from "components/elements/skeleton/SkeletonBreadCrumbs";

import { SkeletonStaticPage } from "components/elements/skeleton/blog/SkeletonStaticPage";

import { CallNow } from "components/static/phone/CallNow";

const service = new ServiceService();
const ServiceStatic: React.FC<IPage> = ({ currentRoute }) => {
  const [object, setObject] = useState<ServiceDto | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [idObject, setIdObject] = useState<string>("");

  const getObjectInfo = useCallback(() => {
    let id = CommonTools.processObjectField(currentRoute, [
      "processedData",
      "idobj",
    ]);
    if (!id) {
      id = CommonTools.processObjectField(currentRoute, [
        "processedData",
        "_obj",
        "id",
      ]);
    }
    if (!id) {
      id = CommonTools.processObjectField(currentRoute, [
        "processedData",
        "_getVars",
        "id",
      ]);
    }
    const object =
      currentRoute &&
      currentRoute.processedData &&
      currentRoute.processedData._obj
        ? currentRoute.processedData._obj
        : null;

    setIdObject(id);
    setObject(object);
  }, [currentRoute]);

  const getObject = useCallback(() => {
    if (!idObject) return;
    if (object && object.id && object.id === parseInt(idObject)) return;
    setLoading(true);
    service.get(idObject, handleGet, {});
  }, [idObject, object]);

  const handleGet = (result: ResultObjectDTO<ServiceDto>) => {
    if (!result) return;
    if (result.error) return;
    const object = result.obj ? result.obj : null;
    setObject(object);
  };
  const checkLoading = useCallback(() => {
    let loading = false;
    if (!object) loading = true;
    setLoading(loading);
  }, [object]);

  useEffect(() => {
    getObjectInfo();
  }, [getObjectInfo]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  useEffect(() => {
    getObject();
  }, [getObject]);

  if (loading)
    return (
      <Box sx={{ width: "100%" }}>
        <SkeletonBreadCrumb />
        <SkeletonStaticPage />
      </Box>
    );
  if (!object) return null;
  return (
    <Box className="service-static">
      <ServiceTitle object={object} />
      <ServiceBreadcrumb object={object} />
      <ServiceMainImage object={object} />
      <CallNow>
        {CommonTools.processObjectField(object, ["_shortdescription"])}
      </CallNow>
      <ServiceContent object={object} />
      <Box className="container" my={5}>
        <GalleryList objects={object._galleryActiveList} excludeDefault />
      </Box>
      <ServiceVideo object={object} />
      <AttachmentList objects={object._attachmentActiveList} />
    </Box>
  );
};

export { ServiceStatic };
