import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import React from "react";
import { Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "components/elements/button/NavButton";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  item: BreadcrumbDto;
  index: number;
};

const ServiceBreadcrumbItem: React.FC<Props> = ({ item, index }) => {
  const onClick = (e:any) => {
    e.stopPropagation();
    e.preventDefault();
    RouteTools.setHistory(item.url, {});
    setTimeout(() => {
      const element = document.getElementById("services");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);
  };

  return item.url ? (
    <NavButton
      href={item.url}
      onClick={onClick}
      underline="hover"
      key={index}
      color="text.secondary"
    >
      {CommonTools.processObjectField(item, ["label"])}
    </NavButton>
  ) : (
    <Typography key={index} color="text">
      {CommonTools.processObjectField(item, ["label"])}
    </Typography>
  );
};

export { ServiceBreadcrumbItem };
