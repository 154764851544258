import { Box } from "@mui/material";
import { CopyRightPage } from "components/static/page/block/CopyRightPage";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { Logo } from "./Logo";

const Footer: React.FC<IPage> = () => {
  return (
    <Box className="footer" py={2}>
      <Box className="container">
        <Logo style={{ fill: "#fff" }} />
        <CopyRightPage />
      </Box>
    </Box>
  );
};

export { Footer };
