import { Box, Typography } from "@mui/material";
import { Loading } from "components/elements/loading/Loading";
import { useConfig } from "hooks/useConfig";
import { useConfigFile } from "hooks/useConfigFile";
import { useLabel } from "hooks/useLabel";
import IProvider from "interfaces/provider.interface";
import React, { createContext, useCallback, useEffect, useState } from "react";


export const DownloadContext = createContext({});

export const DownloadProvider: React.FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { pageProgressLabel, pageProgressLabelTotal } = useLabel();

  const { pageProgressConfig, pageProgressConfigTotal } = useConfig();

  const { pageProgressConfigFile, pageProgressConfigFileTotal } =
    useConfigFile();

  const calculateProgress = () => {
    const total =
      pageProgressLabelTotal +
      pageProgressConfigTotal +
      pageProgressConfigFileTotal;
    const current =
      pageProgressLabel + pageProgressConfig + pageProgressConfigFile;
    const progress = current / total;
    if (isNaN(progress)) {
      return 0;
    }
    if (progress === Infinity) {
      return 0;
    }
    if (progress > 1) {
      return 1;
    }
    if (progress < 0) {
      return 0;
    }
    return progress;
  };

  const checkLoading = useCallback(() => {
    let loading = false;
    if (pageProgressLabelTotal !== pageProgressLabel) loading = true;
    if (pageProgressConfigTotal !== pageProgressConfig) loading = true;
    if (pageProgressConfigFileTotal !== pageProgressConfigFile) loading = true;
    setLoading(loading);
  }, [
    pageProgressLabelTotal,
    pageProgressConfigTotal,
    pageProgressConfigFileTotal,
    pageProgressLabel,
    pageProgressConfig,
    pageProgressConfigFile,
  ]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);
  
  if (loading)
    return (
      <Box>
        <Loading />
        <Box>
          <Typography>Loading data from server</Typography>
          <Typography>{Math.round(calculateProgress() * 100)}%</Typography>
        </Box>
      </Box>
    );
  return (
    <DownloadContext.Provider value={{}}>{children}</DownloadContext.Provider>
  );
};
