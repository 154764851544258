import React from "react";
import { IPage } from "interfaces/page.interface";
import { Box } from "@mui/material";
import { AdvertisementHomeTopBlock } from "components/static/advertisement/AdvertisementHomeTopBlock";
import { useSeoInfo } from "hooks/useSeoInfo";
import { AboutUsHome } from "components/static/page/block/AboutUsHome";
import { CategoryServiceListHome } from "components/static/categoryservice/CategoryServiceListHome";
import { PortfolioListHome } from "components/static/portfolio/PortolioListHome";
import { PartnerList } from "components/static/partner/PartnerList";
import { ContactRequestHome } from "components/static/contactrequest/ContactRequestHome";

const HomePage: React.FC<IPage> = () => {
  useSeoInfo();
  return (
    <Box>
      <AdvertisementHomeTopBlock />
      <Box id="services">
        <CategoryServiceListHome />
      </Box>
      <Box id="portfolio">
        <PortfolioListHome />
      </Box>
      <Box id="aboutus">
        <AboutUsHome />
      </Box>
      <PartnerList />
      <Box id="contact">
        <ContactRequestHome />
      </Box>
    </Box>
  );
};

export { HomePage };
