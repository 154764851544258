import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import ResultListDTO from "dto/app/resultlist.dto";

import { createDataContext } from "hoc/createDataContext";

import { Status } from "tools/types/status";
import { NavigatorDto } from "dto/static/navigator.dto";
import { NavigatorSection } from "tools/types/navigatorsection";
import { NavigatorService } from "services/static/navigator.service";

export type StateResource = {
  openMainDrawer: boolean;

  menuObjects: Array<NavigatorDto> | null;
};

export type Actions = {
  handleOpenMainDrawer: () => void;

  closeAllDrawers: () => void;
  closeMainDrawer: () => void;
  getListMenu: (
    saveCache: (data: any, identifier: string) => void,
    getCache: (identifier: string) => any
  ) => void;
};

const service = new NavigatorService();

const OPEN_MAIN_DRAWER = "open_main_drawer";
const CLOSE_MAIN_DRAWER = "close_main_drawer";
const CLOSE_ALL_DRAWERS = "close_all_drawers";

const SET_MENU_OBJECTS = "set_menu_objects";

const resourceReducer = (state: StateResource, action: any) => {
  switch (action.type) {
    case OPEN_MAIN_DRAWER: {
      return { ...state, openMainDrawer: true };
    }

    case CLOSE_MAIN_DRAWER: {
      return { ...state, openMainDrawer: false };
    }
    case CLOSE_ALL_DRAWERS: {
      return {
        ...state,
        openMainDrawer: false,
      };
    }

    case SET_MENU_OBJECTS: {
      return { ...state, menuObjects: action.payload };
    }

    default:
      return state;
  }
};

const handleOpenMainDrawer = (dispatch: any) => () => {
  dispatch({ type: OPEN_MAIN_DRAWER });
};

const closeMainDrawer = (dispatch: any) => () => {
  dispatch({ type: CLOSE_MAIN_DRAWER });
};

const closeAllDrawers = (dispatch: any) => () => {
  dispatch({ type: CLOSE_ALL_DRAWERS });
};

const getListMenu =
  (dispatch: any) =>
  (
    saveCache: (data: any, identifier: string) => void,
    getCache: (identifier: string) => any
  ) => {
    const req = new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("navigatorsection", [
          NavigatorSection.MAIN.toString(),
        ]),
        RequestFilterDTO.prepareFilter("idparent", ["0"]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    );

    const cache = getCache(JSON.stringify(req));
    if (cache) {
      dispatch({
        type: SET_MENU_OBJECTS,
        payload: cache.objects,
      });
      return;
    }
    service.getList(handleGetListMenu, { dispatch, saveCache, req }, req);
  };

const handleGetListMenu =
  (dispatch: any) => (result: ResultListDTO<NavigatorDto>, cbParams?: any) => {
    if (!result) return;
    if (result.error) return;
    const objects = result.objects || null;

    dispatch({
      type: SET_MENU_OBJECTS,
      payload: objects,
    });
    if (cbParams && cbParams.saveCache) {
      cbParams.saveCache(result, JSON.stringify(cbParams.req));
    }
  };

export const { Provider, Context } = createDataContext<StateResource, Actions>(
  resourceReducer,
  {
    handleOpenMainDrawer,
    closeMainDrawer,
    closeAllDrawers,

    getListMenu,
  },
  {
    openMainDrawer: false,
    menuObjects: null,
  }
);
