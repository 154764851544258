import React from "react";

import { PartnerDTO } from "dto/static/partner.dto";
import { Box, Link } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { Image } from "components/elements/display/Image";

type Props = {
  item: PartnerDTO;
};

const PartnerItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  if (!CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]))
    return null;
  return (
    <Box className="patnerItem">
      <Link
        href={CommonTools.processObjectField(item, ["url"])}
        target="_blank"
        rel="noreferrer">
        <Image
          url={CommonTools.processObjectField(item, [
            "_galleryDefault",
            "cdnurl",
          ])}
          typeMeasure="percentage"
          sizeInPercentage={{ width: "100%" }}
          withLinK={false}
        />
      </Link>
    </Box>
  );
};
export { PartnerItem };
