import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import React from "react";
import { Typography } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "../button/NavButton";

type Props = {
  item: BreadcrumbDto;
  index: number;
};

const BreadcrumbItem: React.FC<Props> = ({ item, index }) => {
  
  
  return item.url ? (
    <NavButton
      href={item.url}
      underline="hover"
      key={index}
      color="text.secondary"
    >
      {CommonTools.processObjectField(item, ["label"])}
    </NavButton>
  ) : (
    <Typography key={index} color="text">
      {CommonTools.processObjectField(item, ["label"])}
    </Typography>
  );
};

export { BreadcrumbItem };
