import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box, Typography } from "@mui/material";

import { CommonTools } from "tools/utils/common.tool";
import { VideoFile } from "components/elements/display/VideoFile";
import { Config } from "tools/utils/config";
import { min } from "date-fns";

type Props = {
  item: AdvertisementDto;
  index: number;
  isHomePage?: boolean;
};

const AdvertisementVideoItemHomeTop: React.FC<Props> = ({
  item,
  isHomePage,
}) => {
  const processMedia = () => {
    if (!item) return null;
    if (!CommonTools.processObjectField(item, ["_filecdnurl"])) return null;

    return (
      <VideoFile
        url={CommonTools.processObjectField(item, ["_filecdnurl"])}
        controls={false}
        width="100%"
        height="100vh"
      />
    );
  };
  if (!item) return null;
  if (!CommonTools.processObjectField(item, ["_filecdnurl"])) return null;
  return (
    <Box
      className="bannerVideoHome"
      sx={{
        height: isHomePage ? `calc(100%)` : { xs: "20vh", md: "40vh" },
        maxHeight: isHomePage ? "none" : "570px",
      }}>
      <Box className="bgVideo">{processMedia()}</Box>
      <Box className="positionContent">
        <Typography className="text">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Box>
    </Box>
  );
};

export { AdvertisementVideoItemHomeTop };
