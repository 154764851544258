import { Box } from "@mui/material";
import { IPage } from "interfaces/page.interface";
import React from "react";

import { Logo } from "components/general/Logo";

import { LanguageBlock } from "components/header/LanguageBlock";
import { MenuBlock } from "components/header/MenuBlock";
import { HeaderNavigator } from "components/static/menu/top/HeaderNavigator";
import { PhoneAction } from "components/static/phone/PhoneAction";

const Header: React.FC<IPage> = () => {
  return (
    <Box className="headerContent container">
      <Logo />
      <Box className="TopNavigatorLevel">
        <HeaderNavigator />
      </Box>
      <Box className="numberBox">
        <PhoneAction className={"headerCallNow"} />
      </Box>
      <Box className="languageBlockHeader">
        <LanguageBlock />
      </Box>

      <Box className="headerMenuBlock">
        <MenuBlock colorSvg={"white"} />
      </Box>
    </Box>
  );
};

export { Header };
