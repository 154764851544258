import React from "react";
import { LanguageDto } from "dto/static/language.dto";
import { ListItemButton } from "@mui/material";
import { CommonTools } from "tools/utils/common.tool";
import { useLanguage } from "providers/LanguageProvider";
type Props = {
  item: LanguageDto;
};
const LanguageSiteItem: React.FC<Props> = ({ item }) => {
  const { selectedLanguageCode, changeLanguage } = useLanguage();
  const isSelected =
    selectedLanguageCode === CommonTools.processObjectField(item, ["code"]);

  const onClick = (event: any) => {
    if (isSelected) return;
    if (!CommonTools.processObjectField(item, ["code"])) return;
    changeLanguage(CommonTools.processObjectField(item, ["code"]));
  };

  return (
    <ListItemButton
      key={CommonTools.processObjectField(item, ["id"])}
      onClick={onClick}
      selected={isSelected}
      sx={{
        fontWeight: isSelected ? 200 : 400,
      }}
      className="btnLanguage"
    >
      {CommonTools.processObjectField(item, ["code"])}
    </ListItemButton>
  );
};

export { LanguageSiteItem };
