import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box } from "@mui/material";

import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "components/elements/button/NavButton";
import { HtmlTag } from "components/elements/display/HtmlTag";

type Props = {
  item: AdvertisementDto;
  index: number;
};

const AdvertisementItemHomeTop: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Box>
      <Box
        sx={{
          color: "#000",
        }}>
        <HtmlTag
          content={CommonTools.processObjectField(item, ["_description"])}
        />
      </Box>

      <Box
        sx={{
          mt: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box sx={{ width: "200px" }}>
          <NavButton
            buttonTheme="goldenBtn"
            href={CommonTools.processObjectField(item, ["urltogo"])}>
            {CommonTools.processObjectField(item, ["_btname"])}
          </NavButton>
        </Box>
      </Box>
    </Box>
  );
};

export { AdvertisementItemHomeTop };
