import React from "react";
import { Chip } from "@mui/material";
import { ServiceDto } from "dto/static/service.dto";
import { CommonTools } from "tools/utils/common.tool";
import { RouteTools } from "tools/utils/route.tool";

type Props = {
  item: ServiceDto;
};
const ServiceItem: React.FC<Props> = ({ item }) => {
  const handleClick = () => {
    const url = CommonTools.processObjectField(item, ["_urlObj", "urlfull"]);
    if (!url) return;
    RouteTools.setHistory(url, {});
  };
  if (!item) return null;
  return (
    <Chip
      label={CommonTools.processObjectField(item, ["_name"])}
      onClick={handleClick}
      variant="outlined"
      className="chipServices"
    />
  );
};

export { ServiceItem };
