import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Typography,
} from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { MyButton } from "components/elements/button/MyButton";
import { Box } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import { LinkToCookiePolicy } from "components/static/page/block/LinkToCookiePolicy";

type Props = {
  open: boolean;
  handleClose: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CookieInfoDialog: React.FC<Props> = ({ open, handleClose }) => {
  const { LL } = useLabel();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Dialog
        open={open}
        keepMounted
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="md"
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            height: "100%",

            justifyContent: "flex-center",
            alignItems: "flex-start",
            borderRadius: "0px",
          },
          "& .MuiDialog-paper": {
            borderRadius: "0px",
          },
        }}
      >
        <DialogTitle sx={{ py: 3, px: 5, color:"#051324" }} fontSize={14} fontWeight={700}>
          
          {LL("cookie_info_title")}
          
        </DialogTitle>
        <DialogContent sx={{ px: 5, pb: 0, height: "100px" }}>
          <DialogContentText
            sx={{mb: 3}}
            fontSize={12}
            fontWeight={400}
            id="alert-dialog-slide-description"
          >
           
            {LL("cookie_info_text")}
          </DialogContentText>
          
          <Box component="span" sx={{}}>
            <Typography
              fontSize={12}
              fontWeight={400}
              sx={{ display: "inline", mr: "3px" }}
            >
              {LL("cookie_info_text2")}
            </Typography>
            <LinkToCookiePolicy />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            sx={{ pb: 3, px: 4, width: "100%", justifyContent: "flex-center" }}
          >
            <MyButton cb={handleClose} width={"124px"} customColor="black">
              <Typography fontWeight={700} fontSize={12} sx={{ py: "3px" }}>
                {LL("bt_accept")}
              </Typography>
            </MyButton>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export { CookieInfoDialog };
