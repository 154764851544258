import { Context, Actions, StateResource } from "contexts/drawer.context";

import { useMemo, useContext, useCallback } from "react";
import { useResource } from "./useResource";

type Props = () => {
  handleOpenMainDrawer: () => void;
  closeAllDrawers: () => void;
  getListNavigator: () => void;
  closeMainDrawer: () => void;
} & StateResource;

export const useDrawer: Props = () => {
  const { state, actions } = useContext(Context);
  const { saveCache, getCache } = useResource();
  const {
    openMainDrawer,
    menuObjects,
  } = state;
  const {
    handleOpenMainDrawer,
    closeMainDrawer,
    closeAllDrawers,

    getListMenu,
  } = actions as Actions;

  const getListNavigator = useCallback(() => {
    getListMenu(saveCache, getCache);
  }, []);

  return useMemo(
    () => ({
      openMainDrawer,
      handleOpenMainDrawer,
      closeAllDrawers,
      menuObjects,
      getListNavigator,
      closeMainDrawer,
    }),
    [openMainDrawer, menuObjects, getListNavigator]
  );
};
