import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/common.tool";

export class CategoryServiceDto implements Idto {
  id?: number | string;
  identifier?: string;
  order?: number;

  status?: number;

  hierarchicalcode?: string;
  level?: number;
  idparent?: number | string;

  _idlang?: number | string;

  _name?: string;
  _title?: string;
  _slug?: string;
  _description?: string;
  _shortdescription?: string;
  _metakey?: string;
  _metadescription?: string;

  constructor(
    _idlang?: number | string,
    idparent?: number | string,
    identifier?: string,
    id?: number | string,
    order?: number,
    status?: number,
    _name?: string,
    _title?: string,
    _slug?: string,
    _description?: string,
    _shortdescription?: string,
    _metakey?: string,
    _metadescription?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;
    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this._title = _title || "";
    this._slug = _slug || "";
    this._description = _description || "";
    this._shortdescription = _shortdescription || "";
    this._metakey = _metakey || "";
    this._metadescription = _metadescription || "";
    this.identifier = identifier || "";
    this.idparent = idparent || 0;
  }

  static groupByParent(
    objects: Array<CategoryServiceDto>
  ): Array<GroupedCategory> {
    if (!objects) return [];
    if (!Array.isArray(objects)) return [];
    if (!objects.length) return [];
    const map = new Map();
    const result: GroupedCategory[] = [];

    objects.forEach((item) => {
      map.set(item.id, { object: item, children: [] });
    });

    for (const item of objects) {
      if (item.idparent !== undefined && map.has(item.idparent)) {
        map.get(item.idparent).children.push(map.get(item.id));
      } else {
        result.push(map.get(item.id));
      }
    }

    return result;
  }

  static prepareBreadcrumbs(
    objects: Array<CategoryServiceDto>
  ): Array<BreadcrumbDto> {
    if (!objects) return [];
    if (!Array.isArray(objects)) return [];
    if (!objects.length) return [];
    const breadcrumbs: Array<BreadcrumbDto> = [];
    objects.forEach((item) => {
      breadcrumbs.push(
        new BreadcrumbDto(
          CommonTools.processObjectField(item, ["_name"]),
          "/"
        )
      );
    });

    return breadcrumbs;
  }

  static generateUrl(id: number | string) {
    return `/categoryservice/show/${id}`;
  }
}

export type GroupedCategory = {
  object: CategoryServiceDto;
  children: Array<GroupedCategory>;
};
