import { Box } from "@mui/material";
import { MenuItem } from "components/static/menu/general/MenuItem";
import React from "react";

import { NavigatorDto } from "dto/static/navigator.dto";
import { useDrawer } from "hooks/useDrawer";

type Props = {
  item: NavigatorDto;
};

const DrawerNavigatorItem: React.FC<Props> = ({ item }) => {
  const { closeAllDrawers } = useDrawer();
  const onClick = () => {
    closeAllDrawers();
  };
  if (!item) return null;

  return (
    <Box className="drawerMenuItems">
      <MenuItem item={item} callback={onClick} />
    </Box>
  );
};

export { DrawerNavigatorItem };
