import { Box, Skeleton } from "@mui/material";
import { MyBreadcrumbs } from "components/elements/breadcrumb/MyBreadcrumbs";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { ServiceDto } from "dto/static/service.dto";
import { CategoryServiceDto } from "dto/static/categoryservice.dto";
import { useList } from "hooks/useList";
import React from "react";
import { CategoryServiceService } from "services/static/categoryservice.service";
import { Status } from "tools/types/status";
import { CommonTools } from "tools/utils/common.tool";
import { ServiceBreadcrumbItem } from "./ServiceBreadcrumbItem";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";

type Props = {
  object: ServiceDto;
};
const service = new CategoryServiceService();
const ServiceBreadcrumb: React.FC<Props> = ({ object }) => {
  const codeCategory = CommonTools.processObjectField(object, ["categorycode"]);

  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };

  const [loading, objects] = useList<CategoryServiceDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("hpcodes", [codeCategory]),
      ],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("level", true)]
    ),
    [codeCategory]
  );

  const processItem = (item: BreadcrumbDto, index: number) => {
    return <ServiceBreadcrumbItem item={item} index={index} key={index} />;
  };
  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Box className="container" py={0}>
      <MyBreadcrumbs
        objects={CategoryServiceDto.prepareBreadcrumbs(objects)}
        processItem={processItem}
      />
    </Box>
  );
};

export { ServiceBreadcrumb };
