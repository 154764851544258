import { Box } from "@mui/material";
import { NavigatorDto } from "dto/static/navigator.dto";
import { useConfig } from "hooks/useConfig";
import React, { useEffect, useState } from "react";
import { TopNavigatorLevel } from "./TopNavigatorLevel";
import { CommonTools } from "tools/utils/common.tool";
import { MenuItem } from "../general/MenuItem";

type Props = {
  item: NavigatorDto;
  level: number;
  color: string;
};

const TopNavigatorItem: React.FC<Props> = ({ item, level, color }) => {
  const { CC } = useConfig();
  const maxLevel = parseInt(CC("NAVIGATOR_TOP_MAX_LEVEL", "1"));

  const [nextLevel, setNextLevel] = useState<number>(1);
  useEffect(() => {
    setNextLevel(level + 1);
  }, [level]);

  const processChildren = () => {
    if (nextLevel > maxLevel) return null;
    return (
      <>
        <TopNavigatorLevel
          idParent={CommonTools.processObjectField(item, ["id"])}
          level={nextLevel}
          color={color}
        />
      </>
    );
  };
  if (!item) return null;
  const myStyle1 = {
  };

  return (
    <Box className="item">
      <MenuItem myStyle={myStyle1} item={item} />
      {processChildren()}
    </Box>
  );
};

export { TopNavigatorItem };
