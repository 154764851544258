import { Skeleton, Stack } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { ServiceDto } from "dto/static/service.dto";

import { useList } from "hooks/useList";
import React from "react";
import { ServiceService } from "services/static/service.service";
import { Status } from "tools/types/status";
import { ServiceItem } from "./ServiceItem";

type Props = {
  idCategory: string;
};
const service = new ServiceService();
const ServiceListHome: React.FC<Props> = ({ idCategory }) => {
  const getList = (cb?: any, cbParma?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParma, req);
  };

  const [loading, objects] = useList<ServiceDto>(
    getList,
    new RequestListDTO(
      [
        RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()]),
        RequestFilterDTO.prepareFilter("idcategory", [idCategory]),
      ],
      1,
      -1,
      []
    ),
    [],
    true,
    `service_home_${idCategory}`
  );
  if (!idCategory) return null;
  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <>
      {objects.map((item, index) => (
        <ServiceItem key={index} item={item} />
      ))}
    </>
  );
};

export { ServiceListHome };
