import { Box, Skeleton, Typography } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { PortfolioDTO } from "dto/static/portfolio.dto";
import { useResponsive } from "hooks/useResponsive";

import { useList } from "hooks/useList";
import React from "react";
import { PortfolioService } from "services/static/portfolio.service";
import { Status } from "tools/types/status";

import { useLabel } from "hooks/useLabel";
import { Carousel } from "components/elements/carousel/MyCarousel";
import { CommonTools } from "tools/utils/common.tool";
import { useConfig } from "hooks/useConfig";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { PortfolioItem } from "./PortfolioItem";

const service = new PortfolioService();
const PortfolioListHome: React.FC = () => {
  const { LL } = useLabel();
  const { CC } = useConfig();
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const { downSM, downMD, downLG, downXL } = useResponsive();

  const [loading, objects] = useList<PortfolioDTO>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "portfoliolisthome"
  );

  const processItem = (item: PortfolioDTO, index: number) => {
    if (!item) return null;

    return <PortfolioItem item={item} key={index} />;
  };
  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;

  let slidesToShow = 1;
  if (downSM) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      1,
      objects.length
    );
  } else if (downMD) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      2,
      objects.length
    );
  } else if (downLG) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      3,
      objects.length
    );
  } else if (downXL) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      4,
      objects.length
    );
  } else {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("show_portfolio_home", "4")),
      objects.length
    );
  }

  return (
    <Box className="container" mb={1}>
      <Typography variant="h2" sx={{ mb: 5 }}>
        {LL("portfolio_list_home_page")}
      </Typography>

      {objects.length > 1 ? (
        <Carousel _slidesToShow={slidesToShow}>
          {objects.map((item: PortfolioDTO, index: number) =>
            processItem(item, index)
          )}
        </Carousel>
      ) : (
        processItem(objects[0], 0)
      )}
    </Box>
  );
};

export { PortfolioListHome };
