import React from "react";

import { PartnerDTO } from "dto/static/partner.dto";
import { Box } from "@mui/material";
import { useList } from "hooks/useList";
import { PartnerService } from "services/static/partner.service";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { Status } from "tools/types/status";
import { PartnerItem } from "./PartnerItem";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { SkeletonPartnerList } from "components/elements/skeleton/SkeletonPartnerList";
import { CommonTools } from "tools/utils/common.tool";
import { useResponsive } from "hooks/useResponsive";
import { useConfig } from "hooks/useConfig";
import { Carousel } from "components/elements/carousel/MyCarousel";

const service = new PartnerService();
const PartnerList: React.FC = () => {
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  const { downSM, downMD, downLG, downXL } = useResponsive();
  const { CC } = useConfig();
  const [loading, objects] = useList<PartnerDTO>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "partner_page_site"
  );

  const processItem = (item: PartnerDTO, index: number) => {
    if (!item) return null;
    return (
      <Box
        key={index}
      >
        <PartnerItem item={item} />
      </Box>
    );
  };
  if (loading)
    return (
      <Box mt={3}>
        <SkeletonPartnerList lines={4} />
      </Box>
    );
  if (!objects) return null;
  if (!objects.length) return null;
  let slidesToShow = 1;
  if (downSM) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      3,
      objects.length
    );
  } else if (downMD) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      3,
      objects.length
    );
  } else if (downLG) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      4,
      objects.length
    );
  } else if (downXL) {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      8,
      objects.length
    );
  } else {
    slidesToShow = CommonTools.calculateNumberOfElementsShowOnCarousel(
      parseInt(CC("show_partner_home", "10")),
      objects.length
    );
  }
  return (
    <Box className="container">
    <Box className="partner " mb={5}>
      <Box className="mask"></Box>
      <Carousel _slidesToShow={slidesToShow}>
        {objects.map((item: PartnerDTO, index: number) =>
          processItem(item, index)
        )}
      </Carousel>
    </Box>
    </Box>
  );
};

export { PartnerList };
