import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogProps,
} from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = DialogProps & {
  open: boolean;
  children: React.ReactNode;
  handleClose: () => void;
  title?: string;
};

const GeneralDialog: React.FC<Props> = ({
  children,
  open,
  handleClose,
  title,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="general-dialog-title"
      aria-describedby="general-dialog-description"
      onClose={handleClose}
      className="dialogGeneral"
      {...props}
    >
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        className="closeButton"
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export { GeneralDialog };
