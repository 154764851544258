import React from "react";

import { AdvertisementDto } from "dto/static/advertisement.dto";
import { Box } from "@mui/material";
import { useAdvertisementList } from "hooks/useAdvertisementList";
import { AdvertisementSection } from "tools/types/advertisementsection";
import { Carousel } from "components/elements/carousel/MyCarousel";
import { AdvertisementItem } from "./AdvertisementItem";
import { SkeletonBanner } from "components/elements/skeleton/SkeletonMainBanner";


const AdvertisementHomeTopBlock: React.FC = () => {
  const { objects, loading } = useAdvertisementList(
    AdvertisementSection.HOME_TOP,
    true,
    "home_top_advertisement_list"
  );

  const processItem = (item: AdvertisementDto, index: number) => {
    if (!item) return null;
    return (
      <AdvertisementItem
        key={index}
        index={index}
        item={item}
        isHomePage={isHomePage}
      />
    );
  };

  const isHomePage = window.location.pathname === "/";

  if (loading) {
    return <SkeletonBanner />;
  }
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Box className="mainBanner">
      {objects.length === 1 ? (
        objects.map((item: AdvertisementDto, index: number) =>
          processItem(item, index)
        )
      ) : (
        <Carousel _slidesToShow={1} carouselStyle="primary">
          {objects.map((item: AdvertisementDto, index: number) =>
            processItem(item, index)
          )}
        </Carousel>
      )}
    </Box>
  );
};

export { AdvertisementHomeTopBlock };
