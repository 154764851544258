import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { PhoneAction } from "./PhoneAction";
import { useLabel } from "hooks/useLabel";
import { MyButton } from "components/elements/button/MyButton";
import { ContactRequestDialog } from "../contactrequest/ContactRequestDialog";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import { logger } from "tools/utils/logger";

type Props = {
  children?: React.ReactNode;
};
const CallNow: React.FC<Props> = ({ children = null }) => {
  const { LL } = useLabel();
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [translateX, setTranslateX] = useState("100%");

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (buttonRef.current) {
      const buttonWidth = buttonRef.current.offsetWidth;
      setTranslateX(`${buttonWidth}px`);
    }
  }, []);

  logger("CallNow", translateX);
  return (
    <Box className="container callNowSection">
      <Typography variant="h4">
        {children ? children : LL("service_phone_call_info")}
      </Typography>
      <Box sx={{ width: "100%", maxWidth: "500px" }} ref={buttonRef}>
        {/* <PhoneAction className={"callNowButton"} label={true} /> */}
        <MyButton
          className="btnSubmit emailButtonMain button112"
          cb={handleOpen}>
          <Typography className="text">{LL("contact_us_now")}</Typography>
          <Box className="icon-container">
            <Box className="icon ">
              <PhoneInTalkIcon />
            </Box>
          </Box>
        </MyButton>
      </Box>
      <ContactRequestDialog open={open} handleClose={handleClose} />
    </Box>
  );
};

export { CallNow };
