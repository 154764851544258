import { useDrawer } from "hooks/useDrawer";
import React from "react";
import { Box, Drawer, List } from "@mui/material";
import { Config } from "tools/utils/config";
import { DrawerCloseButton } from "./DrawerCloseButton";
import { SkeletonMenuRow } from "components/elements/skeleton/SkeletonMenu";
import { DrawerNavigatorItem } from "./DrawerNavigatorItem";
import { NavigatorDto } from "dto/static/navigator.dto";
import { LanguageBlock } from "components/header/LanguageBlock";
import { PhoneAction } from "components/static/phone/PhoneAction";

const MyDrawer: React.FC = () => {
  const { openMainDrawer, closeAllDrawers, menuObjects } = useDrawer();

  const processList = () => {
    if (!menuObjects)
      return (
        <Box sx={{ px: 6 }}>
          <SkeletonMenuRow lines={10} />
        </Box>
      );
    if (!menuObjects.length) return null;

    if (!menuObjects) return null;
    return menuObjects.map((item, index) => processItem(item, index));
  };

  const processItem = (item: NavigatorDto, index: number) => {
    return <DrawerNavigatorItem key={index} item={item} />;
  };

  return (
    <Drawer
      open={openMainDrawer}
      onClose={closeAllDrawers}
      anchor={Config.DRAWER_ANCHOR}
      style={{}}>
      <Box sx={{ position: "relative" }}>
        <DrawerCloseButton />
      </Box>

      <Box
        sx={{
          width: { xs: "auto", sm: Config.DRAWER_WIDTH_MAIN },
          mt: 10,
        }}>
        <List>{processList()}</List>
      </Box>
      <Box className="drawerLanguage">
        <LanguageBlock />
      </Box>
      <Box>
        <PhoneAction />
      </Box>
    </Drawer>
  );
};

export { MyDrawer };
