import { Box, Card, CardActionArea, CardMedia } from "@mui/material";
import { IconType } from "components/elements/icon/IconContext";
import { MyIcon } from "components/elements/icon/MyIcon";
import { PortfolioDTO } from "dto/static/portfolio.dto";
import React, { useState } from "react";
import { CommonTools } from "tools/utils/common.tool";
import { PortfolioDetail } from "./PortofolioDetail";

type Props = {
  item: PortfolioDTO;
};

const PortfolioItem: React.FC<Props> = ({ item }) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Card
        elevation={0}
        className="cardPortfolio"
        sx={{
          maxHeight: 546,
          maxWidth: 440,
        }}
      >
        <CardActionArea onClick={handleClick} sx={{ boxShadow: "none" }}>
          {CommonTools.processImageUrl(
            CommonTools.processObjectField(item, ["_galleryDefault", "cdnurl"]),
            440,
            548
          ) ? (
            <CardMedia
              sx={{ objectFit: "cover", width: "auto", height: "auto"  }}
              component="img"
              className="img"
              image={CommonTools.processImageUrl(
                CommonTools.processObjectField(item, [
                  "_galleryDefault",
                  "cdnurl",
                ]),
                440,
                546
              )}
              alt={CommonTools.processObjectField(item, ["_name"])}
            />
          ) : (
            <Box
              sx={{
                minHeight: "500px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "red",
              }}
            >
              <MyIcon
                type={IconType.NO_IMAGE}
                colorSvg="white"
                height="100px"
                width="100px"
              />
            </Box>
          )}
        </CardActionArea>
      </Card>
      <PortfolioDetail object={item} open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export { PortfolioItem };
