import { Box } from "@mui/material";
import { Image } from "components/elements/display/Image";

import { ServiceDto } from "dto/static/service.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: ServiceDto;
};

const ServiceMainImage: React.FC<Props> = ({ object }) => {
  if (!CommonTools.processObjectField(object, ["_galleryDefault", "cdnurl"]))
    return null;
  return (
    <Box sx={{ maxWidth: "100%"}} my={4}>
      <Image
        url={CommonTools.processObjectField(object, [
          "_galleryDefault",
          "cdnurl",
        ])}
        typeMeasure="percentage"
        withLinK={false}
        // sizeInPx={{
        //   width: 400,
        //   height: 400,
        // }}
        sizeInPercentage={{
          width: "100%",
          height: "auto",
        }}
      />
    </Box>
  );
};

export { ServiceMainImage };
