import { Box, Skeleton, Grid, Typography, Stack } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { useLabel } from "hooks/useLabel";
import { useList } from "hooks/useList";
import React from "react";
import { CategoryServiceService } from "services/static/categoryservice.service";
import { Status } from "tools/types/status";
import { CategoryServiceItem } from "./CategorySeriveItem";
import { CategoryServiceDto } from "dto/static/categoryservice.dto";

const service = new CategoryServiceService();
const CategoryServiceListHome: React.FC = () => {
  const getList = (cb?: any, cbParma?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParma, req);
  };
  const { LL } = useLabel();
  const [loading, objects] = useList<CategoryServiceDto>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "service_categories_home"
  );

  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;
  return (
    <Grid className="container services paddingGeneral" container>
      <Grid item xs={12} sm={2}><Typography variant="h2">{LL("service_categories_home")}</Typography></Grid>
      <Grid item  xs={12} sm={10}>
        {objects.map((item, index) => (
          <CategoryServiceItem key={index} item={item} />
        ))}
        </Grid>
    </Grid>
  );
};

export { CategoryServiceListHome };
