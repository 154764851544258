import { Box, Typography, Grid } from "@mui/material";
import { HtmlTag } from "components/elements/display/HtmlTag";

import { usePage } from "hooks/usePage";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

import { SkeletonAboutUs } from "components/elements/skeleton/SkeletonAboutUs";
import { BenefitListHome } from "components/static/benefit/BenefitListHome";

const AboutUsHome: React.FC = () => {
  const id = Config.ID_ABOUT_US_ON_HOME_PAGE_BLOCK;
  const { object, loading } = usePage(id, true, "about_us_block_home");

  if (!id) return null;
  if (loading)
    return (
      <Box sx={{ mt: 3 }}>
        <SkeletonAboutUs />
      </Box>
    );
  if (!object) return null;
  return (
    <>
      <Grid container className="container paddingGeneral aboutUs" mt={5}>
        <Grid item xs={12} sm={2} pt={2}>
          <Typography variant="h2">
            {CommonTools.processObjectField(object, ["_name"])}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} py={1}>
          <Typography className="text" component={"div"}>
            <HtmlTag
              content={CommonTools.processObjectField(object, ["_description"])}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} pt={2}>
          <BenefitListHome />
        </Grid>
      </Grid>
    </>
  );
};

export { AboutUsHome };
