import { Box, Link } from "@mui/material";
import { useConfig } from "hooks/useConfig";
import { useLabel } from "hooks/useLabel";
import React from "react";
import { Config } from "tools/utils/config";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";

type Props = {
  className?: string;
  label?: boolean;
};

const PhoneAction: React.FC<Props> = ({ className = "", label }) => {
  const { CC } = useConfig();
  const { LL } = useLabel();
  const phone = CC(Config.PHONE_CONFIG);

  if (!phone) return null;
  return (
    <Link className={className} href={`tel:${phone}`}>
      {label ? (
        <Box display="flex" alignItems="center">
          <PhoneInTalkIcon />
          <Box ml={1}>{LL("call")}</Box>
        </Box>
      ) : (
        phone
      )}
    </Link>
  );
};

export { PhoneAction };
