import React from "react";
import { TextField, TextFieldProps } from "@mui/material";

export type Props = TextFieldProps & {
  field: string;
  label: string;
  setObjectField: (field: string, value: any) => void;
  selectTheme?: any;
};

const MyTextField: React.FC<Props> = ({
  label,
  field,
  setObjectField,
  selectTheme,

  ...props
}) => {
  const handleChange = (event: any) => {
    setObjectField(field, event.target.value);
  };

  return (
    <TextField
      label={label}
      onFocus={handleChange}
      onChange={handleChange}
      {...props}
      fullWidth
      variant="standard"
    />
  );
};

export { MyTextField };
