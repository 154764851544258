import { Box } from "@mui/material";
import { VideoPlayer } from "components/elements/display/VideoPlayer";
import { ServiceDto } from "dto/static/service.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: ServiceDto;
};

const ServiceVideo: React.FC<Props> = ({ object }) => {
  if (!object) return null;
  if (!CommonTools.processObjectField(object, ["_videoDefault", "video_id"]))
    return null;
  if (!CommonTools.processObjectField(object, ["_videoDefault", "video_type"]))
    return null;

  return (
    <Box>
      <Box className="videoService">
        <VideoPlayer
          videoId={CommonTools.processObjectField(object, [
            "_videoDefault",
            "video_id",
          ])}
          type={parseInt(
            CommonTools.processObjectField(object, [
              "_videoDefault",
              "video_type",
            ])
          )}
          width={500}
          height={300}
        />
      </Box>
    </Box>
  );
};

export { ServiceVideo };
