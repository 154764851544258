import React from "react";
import { Box, Stack, Grid, Typography, Divider } from "@mui/material";
import { CategoryServiceDto } from "dto/static/categoryservice.dto";
import { CommonTools } from "tools/utils/common.tool";
import { ServiceListHome } from "../service/home/ServiceListHome";

type Props = {
  item: CategoryServiceDto;
};
const CategoryServiceItem: React.FC<Props> = ({ item }) => {
  if (!item) return null;
  return (
    <Grid container className="list">
      <Grid item xs={12} sm={3}>
        <Typography variant="h3">
          {CommonTools.processObjectField(item, ["_name"])}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <ServiceListHome
          idCategory={CommonTools.processObjectField(item, ["id"])}
        />
      </Grid>
    </Grid>
  );
};

export { CategoryServiceItem };
