import { Box, Typography } from "@mui/material";
import { PageDto } from "dto/static/page.dto";
import React from "react";
import { CommonTools } from "tools/utils/common.tool";

type Props = {
  object: PageDto;
  textColor?: string;
};

const PageTitle: React.FC<Props> = ({ object, textColor }) => {
  if (!object) return null;
  return (
    <Box className="container" mt={5}>
      <Typography variant="h2">
        {CommonTools.processObjectField(object, ["_name"])}
      </Typography>
    </Box>
  );
};

export { PageTitle };
