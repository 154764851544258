import { NavigatorDto } from "dto/static/navigator.dto";
import React from "react";

type Props = {
  objects: NavigatorDto[];
  processItem: (item: NavigatorDto, index: number) => JSX.Element | null;
};

const TopNavigatorList: React.FC<Props> = ({ objects, processItem }) => {

    
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <React.Fragment>
      {objects.map((item: NavigatorDto, index: number) =>
        processItem(item, index)
      )}
    </React.Fragment>
  );
};

export { TopNavigatorList };
