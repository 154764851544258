import { Box, Skeleton, Grid } from "@mui/material";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { BenefitDTO } from "dto/static/benefit.dto";
import { useList } from "hooks/useList";
import React from "react";
import { BenefitService } from "services/static/benefit.service";
import { Status } from "tools/types/status";

import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { BenefitItem } from "./BenefitItem";


const service = new BenefitService();
const BenefitListHome: React.FC = () => {
  
  const getList = (cb?: any, cbParams?: any, req?: RequestListDTO) => {
    service.getList(cb, cbParams, req);
  };
  

  const [loading, objects] = useList<BenefitDTO>(
    getList,
    new RequestListDTO(
      [RequestFilterDTO.prepareFilter("status", [Status.ACTIVE.toString()])],
      1,
      -1,
      [RequestSortCriteriaDTO.prepareSortCriteria("order", true)]
    ),
    [],
    true,
    "benefitlisthome"
  );

  const processItem = (item: BenefitDTO, index: number) => {
    if (!item) return null;

    return <BenefitItem item={item} key={index} />;
  };
  if (loading) return <Skeleton />;
  if (!objects) return null;
  if (!objects.length) return null;

  return (
    <Grid container>
      {objects.map((item: BenefitDTO, index: number) =>
        processItem(item, index)
      )}
    </Grid>
  );
};

export { BenefitListHome };
