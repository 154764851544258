import SelectOptions from "dto/app/selectoptions.dto";

import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { Status } from "tools/types/status";


export class ContactRequestDTO implements Idto {
  id?: number | string;
  date?: number;
  
  idtypedepartment?: number;
  
  status?: number;
  name?:  string;
  reference?: string;
  phone?: string;

  email?: string;
  message?: string;
  response?: string;

  constructor(
    id?: number | string,
    status?: number,
    name?: string,
    reference?: string,
    phone?: string,
    email?: string,
    message?: string,
    response?: string,
    date?: number,
    idtypedepartment?: number
  ) {
    this.id = id || 0;
    this.status = status || Status.UNPROCESSED;
    this.name = name || "";
    this.reference = reference || "";
    this.phone = phone || "";
    this.email = email || "";
    this.message = message || "";
    this.response = response || "";
    this.date = date || 0;
    this.idtypedepartment = idtypedepartment || 0;
  }

  

  static parseToSelectOptions(
    data: Array<ContactRequestDTO>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["name"])
        )
      );
    });
    return result;
  }
}
