import React, { useLayoutEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import theme from "assets/themes/theme";

import { ThemeProvider } from "@mui/material/styles";
import { ResourceProvider } from "providers/ResourceProvider";
import { MessageProvider } from "providers/MessageProvider";
import { LabelProvider } from "providers/LabelProvider";
import { RoutesProvider } from "providers/RoutesProvider";
import { HeadProvider } from "providers/HeadProvider";
import { JWTProvider } from "providers/JWTProvider";
import { LanguageProvider } from "providers/LanguageProvider";
import { UserProvider } from "providers/UserProvider";
import { ConfigProvider } from "providers/ConfigProvider";
import GeneralAxiosRepository from "repositories/generalaxios.repository";
import { UserAgentProvider } from "react-ua";
import { BotProvider } from "providers/BotProvider";
import { CookieUsageProvider } from "providers/CookieUsageProvider";
import { ServerError } from "components/general/ServerError";
import { LocalStorageTools } from "api/localstorage.api";
import { RouteTools } from "tools/utils/route.tool";
import { ConfigFileProvider } from "providers/ConfigFileProvider";
import { Provider as DrawerProvider } from "contexts/drawer.context";

import AOS from "aos";
import ReactGA from 'react-ga4';
import TagManager from "react-gtm-module";
import { Config } from "tools/utils/config";
import { DownloadProvider } from "providers/DownloadProvider";

const App: React.FC = () => {
  const [_theme, setTheme] = useState({});
  const [serverError, setServerError] = useState<boolean>(false);

  const handleReload = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  useLayoutEffect(() => {
    setTheme(theme);
    AOS.init({
      duration: 2000,
    });
    TagManager.initialize(Config.TAG_MANAGER_ARGS);
    ReactGA.initialize(Config.GA_ID);
    GeneralAxiosRepository.setReloadFunction(handleReload);
    GeneralAxiosRepository.setServerError(processServerError);
    GeneralAxiosRepository.setHandleUnauthorized(handleUnauthorized);
    GeneralAxiosRepository.setForbiddenFunction(handleForbidden);
  }, []);

  const handleUnauthorized = () => {
    LocalStorageTools.saveValue("token", "");
    LocalStorageTools.saveValue("remember_token", "");
    RouteTools.setHistory("/login", {});
    handleReload();
  };

  const handleForbidden = () => {
    RouteTools.setHistory("/forbidden", {});
    handleReload();
  };

  const processServerError = () => {
    setServerError(true);
  };

  if (serverError)
    return (
      <ThemeProvider theme={_theme}>
        <ServerError />
      </ThemeProvider>
    );

  return (
    <UserAgentProvider>
      <BotProvider>
        <ThemeProvider theme={_theme}>
          <JWTProvider>
            <CookiesProvider
              defaultSetOptions={{
                sameSite: "strict",
              }}
            >
              <HeadProvider>
                <LanguageProvider>
                  <UserProvider>
                    <ResourceProvider>
                      <LabelProvider>
                        <MessageProvider>
                          <ConfigProvider>
                            <ConfigFileProvider>
                              <DownloadProvider>
                                <CookieUsageProvider>
                                  <DrawerProvider>
                                    <RoutesProvider />
                                  </DrawerProvider>
                                </CookieUsageProvider>
                              </DownloadProvider>
                            </ConfigFileProvider>
                          </ConfigProvider>
                        </MessageProvider>
                      </LabelProvider>
                    </ResourceProvider>
                  </UserProvider>
                </LanguageProvider>
              </HeadProvider>
            </CookiesProvider>
          </JWTProvider>
        </ThemeProvider>
      </BotProvider>
    </UserAgentProvider>
  );
};

export default App;
