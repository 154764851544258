import SelectOptions from "dto/app/selectoptions.dto";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { Status } from "tools/types/status";


export class NomenclatureDto implements Idto {
  id?: number | string;
  identifier?: string;
  order?: number;
  status?: number;
  _idlang?: number | string;
  _name?: string;

  constructor(
    _idlang?: number | string,
    id?: number | string,
    identifier?: string,
    order?: number,
    status?: number,
    _name?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;
    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
  }

  

  static parseToSelectOptions(data: Array<NomenclatureDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["_name"])
        )
      );
    });
    return result;
  }
}
