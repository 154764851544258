import React from "react";
import { LanguageDto } from "dto/static/language.dto";
import { List } from "@mui/material";
import { useLanguage } from "providers/LanguageProvider";


import { CommonTools } from "tools/utils/common.tool";
import { LanguageSiteItem } from "./LanguageSiteItem";

const LanguageSiteList: React.FC = () => {
  const { languageSiteObjects } = useLanguage();

  if (!languageSiteObjects || !languageSiteObjects.length) return null;

  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {languageSiteObjects.map((item: LanguageDto) => (
        <LanguageSiteItem
          key={CommonTools.processObjectField(item, ["id"])}
          item={item}
        />
      ))}
    </List>
  );
};

export { LanguageSiteList };
